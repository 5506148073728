import React from "react";
import "./credStory.css";

const CredStory = () => {
  return (
    <div className="cred-story flex absolute-center">
      <div className="max-width flex story-wrapper">
        <div className=" flex cred-story-heading-wrapper">
          <div className="cred-story-heading">
            Copyright &copy; 2023 &nbsp;
            <a
              className="developer-string"
              href="https://dc.developerstring.com"
              target={"_blank"}
              rel="noreferrer"
            >
              Developer String &nbsp;
            </a>
          </div>
          <a
            href="https://term.finspare.com/"
            target={"_blank"}
            rel="noreferrer"
            className="aditya terms-Of-Services"
          >
            Terms & Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
};

export default CredStory;
