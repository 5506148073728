import React from 'react'
import "./windowPeak.css";

const WindowPeak = () => {
  return (
    <div className='window-peek'>

    </div>
  )
}

export default WindowPeak