import React from 'react'
import "./credExpierience.css";


const CredExpierience = () => {
  return (
    <div>
        <div className='cred-experience photo-section'>
        <div className='max-width'>
            <div className='photo-section-child cred-experience-photo-section'>
                <div className='photo-section-top'>
                    <div className='photo-section-heading cred-expierience-heading'>
                    Take control of your finances with ease
                    </div>
                    <div className='photo-section-subheading cred-expierience-subheading'>
                    Empowering You to Achieve Financial Freedom
                    </div>
                </div>

                <div className='photo-section-bottom'>
                    <div className='photo-section-description cred-expierience-description'>
                    With FinSpare, managing your finances is easier. Our budgeting tools and features allow you to take control of your finances, so you can confidently plan for your future.
                    </div>
                </div>
            </div>
        </div>
    </div>  
    </div>
  )
}

export default CredExpierience