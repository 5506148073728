import React from "react";
import "./feelSpecial.css";

const FeelSpecial = () => {
  return (
    <div className="feel-special photo-section">
      <div className="max-width">
        <div className="photo-section-child">
          <div className="photo-section-top">
            <div className="photo-section-heading">
            Achieve your financial goals with FinSpare.
            </div>
            <div className="photo-section-subheading">
            Streamline Your Financial Management with Ease and Confidence
            </div>
          </div>

          <div className="photo-section-bottom">
            <div className="photo-section-description">
            With this app, you'll see exactly where your money is going, identify areas where you can cut back, and plan for future expenses. You'll have all your financial information in one place, making it easy to stay on top of your budget and avoid overspending. It helps you track expenses, create a budget, and achieve your financial goals.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeelSpecial;
