import React from 'react'
import HomePage from './pages/homePage'

// import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
// import { Route, Routes } from "react-router-dom";

const App = () => {
  return <HomePage />
}

export default App