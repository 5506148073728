import React from "react";
import Button from "../common/Button";
import "./heroSection.css";

const HeroSection = () => {
  return (
    <div className="hero-section-wrapper">
      <div className="full-hero-section-wrapper">
        <div className="flex absolute-center flex-col hero-section max-width">
          <div className="hero-heading">
            Manage all your finances at a single place.
          </div>
          <div className="hero-subheading">
          Effectively manage your budget and keep track of your expenses, helping you stay on top of your finances.
          </div>
          <a className="hover-text" href="https://play.google.com/store/apps/details?id=com.developerstring.finspare" target="_blank" rel="noreferrer">
            <Button className="hero-button" buttonText="Download FINSPARE" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
