import React from "react";
import Header from "../components/common/header";
import ContactForm from "../components/ContactForm";
import CredExpierience from "../components/CredExperience";
import CredSecurity from "../components/CredSecurity";
import CredStory from "../components/CredStory";
import FeelSpecial from "../components/FeelSpecial";
import HeroSection from "../components/HeroSection";
import MobileScroll from "../components/MobileScroll";
import ProductShowCase from "../components/ProductShowcase";
import WindowPeak from "../components/WindowPeak";
// import Footer from "./Footer"
// import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
// import { Route, Routes } from "react-router-dom";
// import App from "../App";
// import App from "../App";

const HomePage = () => {
  return (
    <>
    
      {/* <Routes>
        <Route exact path="/" element={ <HomePage /> }>
        </Route>
        <Route path="/footer" element={<Footer />}>
        </Route>
      </Routes> */}
      <Header />
      <HeroSection />
      <ProductShowCase />
      <FeelSpecial />
      <CredExpierience />
      <MobileScroll />
      <WindowPeak />
      <CredSecurity />
      <ContactForm />
      <CredStory />

      {/* <div className="App">
      <h1>React Router DOM</h1>
      <Router>
        <Routes> */}
          {/* <Route path="/" element={<CredStory />}></Route> */}
          {/* <Route exact path="/footer" element={<Footer />}></Route>
        </Routes>
      </Router>
    </div> */}
      
      
    
    </>
  );
};

export default HomePage;
