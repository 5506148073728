import React from "react";
import "./mobileScroll.css";
import ScreenText from "./ScreenText.js";
import { useState } from "react";
import asset6 from "./asset 6.png";
import asset7 from "./asset 7.png";
import asset8 from "./asset 8.png";
import asset9 from "./asset 9.png";

const scrollData = [
  {
    heading: "FinSpare Dashboard:",
    description:
      "Bringing Simplicity to Your Financial World: One Dashboard for All Your Finances.",
    mobile_img: asset6,
  },

  {
    heading: "Personalize, Manage, and Track.",
    description:
      "Manage Your Personal Information and Customize Your Budgeting Goals with Ease in the Profile Section of Our App.",
    mobile_img: asset7,
  },

  {
    heading: "Categorize, Visualize, Budget.",
    description:
      "Visualize Your Spending Habits and Budget Goals with the Category Chart Section of Our App.",
    mobile_img: asset8,
  },

  {
    heading: "Monitor, Progress, Simplify.",
    description:
      "Gain Valuable Insights into Your Spending Habits and Budget Goals with the Comprehensive Graph Section of Our Budgeting App.",
    mobile_img: asset9,
  },
];

const MobileScroll = () => {
  const [currentImg, setCurrentImg] = useState(0);
  return (
    <div className="full-section-wrapper-mobile-scroll">
      <div className="max-width flex mobile-scroll">
        <div className="scroll-full-screen-wrapper">
          {scrollData.map((screen, i) => (
            <div className="scroll-full-screen">
              <ScreenText screen={screen} i={i} setCurrentImg={setCurrentImg} />
            </div>
          ))}
        </div>
        <div className="mobile-mockup-wrapper non-mobile">
          <div className="mobile-mockup">
            <div className="mobile-mockup-screen flex absolute-center">
              <img
                src={scrollData[currentImg].mobile_img}
                className="mobile-screen-img slide-in-right"
                alt="img"
                key={scrollData[currentImg].mobile_img}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileScroll;
