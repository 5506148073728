import React, {useState} from "react";
import "./contactForm.css";


const ContactForm = () => {
   const [userData, setUserData] = useState({
      fullName: "",
      email: "",
      firstImpression: "",
      feedback: "",
   });

   let name, value;
   const postUserData = (event) =>{
      name = event.target.name;
      value = event.target.value;
      setUserData({...userData, [name]: value});
   };

   // Connect with FireBase
   const submitData = async (event) =>{
      event.preventDefault();
      const{ fullName, email, firstImpression, feedback,} = userData;
      const response = fetch(
         'https://finspare-website-default-rtdb.firebaseio.com/userFeedBack.json',
      {
            method: "POST",
            Headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify({
            fullName, 
            email, 
            firstImpression, 
            feedback,
         })
      }
      );

      if(response){
         setUserData({
            fullName: "",
            email: "",
            firstImpression: "",
            feedback: "",
         })
         
         alert("Your Form has been Submited");
      }

      else{
         alert("Please Fill the Data");
      }
   }

  return (
   <div className='contact-form-wrapper'>

    <div className="max-width flex absolute-center">
      <div className="container">

      <div className="text">Please share your FeedBack with US</div>
        <form method="POST">
           <div className="form-row">
              <div className="input-data">
                 <input type="text" name="fullName" value={userData.fullName} onChange={postUserData} required/>
                 <div className="underline"></div>
                 <label for="">Full Name</label>
              </div>
           </div>

           <div className="form-row">
              <div className="input-data textarea text-area-main">
                 <textarea rows="9" cols="90" name="feedback" value={userData.feedback} onChange={postUserData} required></textarea>
                 <br />
                 <div className="underline underline-textarea"></div>
                 <label className="feedback-textarea" for="">Write your FeedBack</label>
                 <br />
              </div>
           </div>
           <div className="form-row">
            <button type="submit" onClick={submitData} className="contact-btn">Submit</button>
           </div>
        </form>
      </div>
    </div>
   </div>
  );
};

export default ContactForm;
