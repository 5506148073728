import React, { useEffect, useRef, useState } from 'react'
import './productShowCase.css';
import asset1 from './Frame 1.png';
import asset2 from './Frame 2.png';
import asset3 from './Frame 3.png';
import asset4 from './Frame 4.png';
import asset5 from './Frame 5.png';

const ProductShowCase = () => {
    const [showAnimation, setShowAnimation] = useState(false);
    
    const ref = useRef(null);

    function toggleAnimation(e) {
        if (e[0].isIntersecting) {
            setShowAnimation(true);
        }
    };
    
    const options ={
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
    };
    
    useEffect(()=>{
        const observer = new IntersectionObserver(toggleAnimation, options);
        const refference = ref.current;

        if(!showAnimation){
            if(refference){
                observer.observe(refference);
            }
        }
        
        return () => {
            if(refference){
                observer.unobserve(refference);
            }
        };
    });
    

  return (
    <div 
        className={`product-showcase ${showAnimation ? 'scale-in-bottom' : ""}`} 
        ref={ref} 
    >
        {showAnimation && ( <div className='showcase-wrapper'>
        <img src={asset4} className='showcase-ui showcase-mockup-1' alt='mockup-img-4'></img>
        <img src={asset3} className='showcase-ui showcase-mockup-2' alt='mockup-img-3'></img>
        <img src={asset1} className='showcase-ui showcase-mockup-3' alt='mockup-img-1'></img>
        <img src={asset5} className='showcase-ui showcase-mockup-4' alt='mockup-img-5'></img>
        <img src={asset2} className='showcase-ui showcase-mockup-5' alt='mockup-img-2'></img>
        </div>)}
    </div>

  )
}

export default ProductShowCase