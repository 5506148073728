import React from 'react'
import "./credSecurity.css";

const CredSecurity = () => {
  return (
    <div className='cred-security photo-section'>
        <div className='max-width'>
            <div className='photo-section-child'>
                <div className='photo-section-top'>
                    <div className='photo-section-heading'>
                    Securing your data is our top priority.
                    </div>
                    <div className='photo-section-subheading'>
                    Protecting Your Personal and Financial Information: Our Unwavering Commitment
                    </div>
                </div>

                <div className='photo-section-bottom'>
                    <div className='photo-section-description'>
                    At FinSpare, we understand the sensitive nature of financial information and the trust our users place in us to secure it. That's why we prioritize the protection of your personal and financial data above all else. It's not just a priority it's a fundamental part of who we are and what we stand for.
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CredSecurity